import React from 'react';
import { Link } from "react-router-dom";
import './App.css';
import { GiShipWheel } from "react-icons/gi";

class Info extends React.Component {
    render() {
        return (
        <div>
            <div>
                <img id='logo' src={require('./Logo.jpg')} alt="Logo" />
                <meta name='viewport' content="initial-scale=1"></meta>
            </div>
            {/* less than sign */}
            <Link class='back_button' to='/'>&lt;</Link>
            <div id='container_info'>
                <h2 id='info_title'><span class='wheel'><GiShipWheel/></span> Real Cruise Price <span
                    class='wheel'><GiShipWheel/></span></h2>
                <p class='info_text'>Hi! Thanks for taking the time to explore our website.
                    We hope it is a helpful tool when planning your next exciting vacation!
                    We started Real Cruise Price after coming back from our first ever cruise.
                    We had a blast on the cruise and couldn't wait to go on another one.
                    However, we found comparing cruise prices between sites with all the different rates, fees, and
                    options confusing and hard to navigate.
                    So we put together a team to gather price data from some of the most popular cruise lines so we
                    could easily
                    compare how much each cruise would REALLY cost. More cruise lines coming soon!</p>
                <img id='pic' src={require('./discovery-pic.jpg')} alt="disco"/>
                <p id='image_text'>Discovery Princess, 2022</p>
                <br/>
                <img id='pic' src={require('./beyond-pic.jpg')} alt="beyond"/>
                <p id='image_text'>Celebrity Beyond, 2023</p>
                <p class='info_text' id='email_text'><br/>For questions, comments, or suggestions email us at:
                    RealCruisePrice@gmail.com</p>
            </div>
        </div>
        );
    }
}

export default Info;