import React from 'react';
import { Link } from "react-router-dom";
import CheckboxTree from 'react-checkbox-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShip, faAnchor } from '@fortawesome/free-solid-svg-icons'
import * as constants from './constants'

import  { FaCocktail, FaWifi, FaRegMoneyBillAlt } from "react-icons/fa"
import { RiHandCoinLine, RiCheckboxIndeterminateLine, RiCheckboxBlankLine, RiCheckboxLine } from "react-icons/ri";

import './App.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "data": [],
      "data_status": "not loaded",
      "data_page": 0,
      "data_last_updated": "",
      "table_rows_expanded": [],
      "dynamic_filters": {
        "cruise_ship_values": [],
        "cruise_ship_nodes": [],
        "depart_port_values": [],
        "depart_port_nodes": [],
        "rate_type_values": [],
        "rate_type_nodes": [],
      },
      "filters": {
        "hide_unavailable": true,
        "min_duration": 3,
        "max_duration": 9,
        "min_depart_date": "2024-03-01",
        "max_depart_date": "2026-01-30",
        "sort": 'total_price_inside',
      },
      "display_price_per_night": false,
      "error_text": "",
      "more_data_possible": true
    }
    this.getData = this.getData.bind(this);
    this.clearData = this.clearData.bind(this);
    this.moreDataSubmit = this.moreDataSubmit.bind(this);
    this.forceMoreDataSubmit = this.forceMoreDataSubmit.bind(this);
    this.flattenedFilters = this.flattenedFilters.bind(this);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.handleWidgitCheck = this.handleWidgitCheck.bind(this);
    this.getFilterValues = this.getFilterValues.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
    this.handleDisplayPriceChange = this.handleDisplayPriceChange.bind(this);
    this.renderGetDataButton = this.renderGetDataButton.bind(this);
    this.loadingMore = true;  // start with loading more true until filters load
    this.firstLoad = true;
    this.current_call_i = 0;
    this.error_text_timeout = null;
  }

  flattenedFilters() {
    let new_dict = {}
    for (const [key, value] of Object.entries(this.state.filters)) {
      console.log(value, typeof(value))
      if (typeof(value) !== "object") {
        new_dict[key] = value
        continue
      }
      for (const [key2, value2] of Object.entries(value)) {
        let new_key = key + "." + key2
        new_dict[new_key] = value2
      }
    }
    new_dict.cruise_ships = this.state.dynamic_filters.cruise_ship_values
    new_dict.depart_ports = this.state.dynamic_filters.depart_port_values
    new_dict.rate_types = this.state.dynamic_filters.rate_type_values
    return new_dict
  }

  componentDidMount() {
    if (!this.firstLoad) {
      return
    }
    this.firstLoad = false
    this.getFilterValues()  // this will also get cruise data on successful retrieve
    window.addEventListener('scroll', this.loadMore);
    window.onbeforeunload = null  // this prevents pop ups like "are you sure you want to reload"
  }

  loadMore() {
      if ((window.innerHeight + document.documentElement.scrollTop + 100) >= document.scrollingElement.scrollHeight)  {
          this.moreDataSubmit();
      }
  }

  handleSortChange(event, name) {
    this.clearData()
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const this_name = name !== undefined ? name : target.name;
    console.log(value)

    this.setState(prevState => {
      return {filters: { ...prevState.filters, [this_name]: value}}
    }, () => {
      this.forceMoreDataSubmit()
    })
  }

  handleDisplayPriceChange(event) {
    const value = event.target.checked;
    const this_name = "display_price_per_night"

    this.setState({
      [this_name]: value
    })
  }

  getFilterValues() {
    const url = constants.BASEURL + "/api/filters"
    console.log("getting filter values ", url)
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
          },
    })
    .then(response => response.json())
    .then((data) => {
      this.setState({
        "dynamic_filters": {
          "cruise_ship_values": data.ship_all_values,
          "cruise_ship_nodes": data.ship_filters,
          "depart_port_values": data.port_all_values,
          "depart_port_nodes": data.port_filters,
          "rate_type_values": data.rate_type_all_values,
          "rate_type_nodes": data.rate_type_filters,
        },
        "data_last_updated": data.data_last_updated
      }, () => {this.forceMoreDataSubmit()})
    })
    .catch((error) => {
      this.setState({
        "data_status": "erorred"
      })
    })
  };

  getData(send_data, call_i) {
    send_data['page'] = this.state.data_page
    const url_params = new URLSearchParams(send_data).toString()
    const url = constants.BASEURL + "/api/cruises?" + url_params
    console.log("getting data ", url)
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
          },
    })
    .then(response => response.json())
    .then((data) => {
      if (call_i !== this.current_call_i) {
        console.log("expired data")
        return
      }
      setTimeout(() => {this.loadingMore = false;}, 1000)
      if (!data.cruises || !data.cruises.length) {
        console.log("no more data")
        this.setState({
          "more_data_possible": false
        })
        return
      }
      let new_data = this.state.data
      if (data.cruises !== null) {
        new_data = new_data.concat(data.cruises)
      }
      this.setState({
        "data": new_data,
        "data_status": "loaded"
      })
    })
    .catch((error) => {
      this.setState({
        "data_status": "erorred"
      })
    })
  };

  clearData() {
    this.setState({
      "data": [],
      "data_status": "not loaded",
      "data_page": 0,
      "table_rows_expanded": [],
      "more_data_possible": true
    })
  }

  // don't check loading more
  forceMoreDataSubmit() {
    this.loadingMore = true;
    this.setState(prevState => {
      return {"data_page": prevState["data_page"] + 1}
    }, () => {
      this.current_call_i += 1;
      this.getData(this.flattenedFilters(), this.current_call_i);
    })
  }

  moreDataSubmit() {
    if (this.loadingMore || !this.state.more_data_possible) {
      return
    }
    this.loadingMore = true;
    this.setState(prevState => {
      return {"data_page": prevState["data_page"] + 1}
    }, () => {
      this.current_call_i += 1;
      this.getData(this.flattenedFilters(), this.current_call_i);
    })
  }

  handleWidgitCheck(checked, targetNode, whichWidgit) {
    let filterStore
    if (whichWidgit === 'cruise_ships') {
      filterStore = "cruise_ship_values"
    } else if (whichWidgit === 'depart_ports') {
      filterStore = "depart_port_values"
    } else if (whichWidgit === 'rate_type') {
      filterStore = "rate_type_values"
    }

    this.clearData()

    this.setState((prevState) => {
      let newFilters = { ...prevState.dynamic_filters};
      newFilters[filterStore] = checked
      return {dynamic_filters: newFilters}
    }, () => {
      this.forceMoreDataSubmit()
    })
  }

  onTableRowClick(i) {
    // remove it if it exists
    if (this.state.table_rows_expanded.includes(i)) {
      this.setState(prevState => {
        let newExpandedRows = [...prevState.table_rows_expanded]
        newExpandedRows = newExpandedRows.filter(item => item !== i)
        return {table_rows_expanded: newExpandedRows}
      })
      return
    }
    // otherwise add it
    this.setState(prevState => {
      let newExpandedRows = [...prevState.table_rows_expanded]
      newExpandedRows.push(i)
      console.log(JSON.stringify(newExpandedRows))
      return {table_rows_expanded: newExpandedRows}
    })
  }

  renderGetDataButton() {
    if (this.state.more_data_possible) {
      return <div class='get_data_div'><button class='get_data' onClick={this.moreDataSubmit}>Get more data</button></div>
    }
    return <div class='get_data_div'><button class='get_data' disabled>No more data</button></div>
  }

  render() {
    return <div>
       <img id='logo' src={require('./Logo.jpg')} alt="Logo" />
       <meta name='viewport' content="initial-scale=1"></meta>
      <FilterForm
        handleSortChange={this.handleSortChange}
        filterData={this.state.filters}
        clear_data={this.clearData}
        handleWidgitCheck={this.handleWidgitCheck}
        dynamicFilters={this.state.dynamic_filters}
        handleDisplayPriceChange={this.handleDisplayPriceChange}
        displayPricePerNight={this.state.display_price_per_night}
        error_text={this.state.error_text}
        data_last_updated={this.state.data_last_updated}
      />
      <DataTable
        data={this.state.data}
        data_status={this.state.data_status}
        onTableRowClick={this.onTableRowClick}
        table_rows_expanded={this.state.table_rows_expanded}
        displayPricePerNight={this.state.display_price_per_night}
      />
      <div id='get_data_container'>
      {this.renderGetDataButton()}
      </div>
    </div>;
  }
}

// todo: rename
class Widget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
  };
    this.onExpand = this.onExpand.bind(this);
  }

  onExpand(expanded, targetNode) {
    this.setState({ expanded })
  }

  render() {
    // https://github.com/jakezatecky/react-checkbox-tree
      return (
          <CheckboxTree
              nodes={this.props.nodes}
              checked={this.props.checked}
              expanded={this.state.expanded}
              onCheck={this.props.handleCheck}
              checkModel="leaf"  // makes checked array only contain leaf nodes
              id="ship_checkbox" 
              onExpand={this.onExpand}
              // showExpandAll={true}
              showNodeIcon={false}
              // nativeCheckboxes={true}
              // expandOnClick={true}
              // onClick={() => {alert('hello')}}
              // iconsClass="fa5"
              icons={{
                check: <RiCheckboxLine/>,
                uncheck: <RiCheckboxBlankLine/>,
                halfCheck: <RiCheckboxIndeterminateLine/>,
                expandClose: <span className="rct-icon rct-icon-expand-close" />,
                // expandOpen: <span className="rct-icon rct-icon-expand-open" />,
                // expandAll: <span className="rct-icon rct-icon-expand-all" />,
                collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                parentClose: <FontAwesomeIcon icon={faAnchor} />,
                parentOpen: <FontAwesomeIcon icon={faAnchor} />,
                leaf: <FontAwesomeIcon icon={faShip} />,
            }}
          />
      );
  }
}

class FilterForm extends React.Component {
  render() {
    return (
      <div id='container'>
      <form>
        <table id='filterstable'>
          <tr>
            <td class='filters_column'><h2 id='filters'>Filters:</h2></td>
            <td class='filters_column'><p id='companies'>Cruise Ship</p></td>
            <td class='filters_column'><p id='start_ports'>Departure Port</p></td>
            <td class='filters_column'><p id='rate'>Rate types</p></td>
            <td class='filters_column'><h2 id='sortby'>Sort:</h2></td>
            <td>
            <label>
            <select id='sort'
              name="sort"
              value={this.props.filterData.sort}
              onChange={this.props.handleSortChange}>
              <option value="total_price_inside">Total Price Inside</option>
              <option value="total_price_balcony">Total Price Balcony</option>
              <option value="per_night_price_inside">Per Night Price Inside</option>
              <option value="per_night_price_balcony">Per Night Price Balcony</option>
              <option value="depart_date">Depart Date</option>
            </select>
          </label>
          </td>
          </tr>
          <tr>
            <td><ErrorMessage text={this.props.error_text} /></td>
            <td>
            <div class='checkbox_div'>
            <Widget
              handleCheck={(c, t) => this.props.handleWidgitCheck(c, t, "cruise_ships")}
              nodes={this.props.dynamicFilters.cruise_ship_nodes}
              checked={this.props.dynamicFilters.cruise_ship_values}
            />
            </div>
            </td>
            <td>
            <div class='checkbox_div'>
            <Widget
              handleCheck={(c, t) => this.props.handleWidgitCheck(c, t, "depart_ports")}
              nodes={this.props.dynamicFilters.depart_port_nodes}
              checked={this.props.dynamicFilters.depart_port_values}
            />
            </div>
            </td>
            <td>
              <span class='checkbox_div_small'>
                <Widget
                  handleCheck={(c, t) => this.props.handleWidgitCheck(c, t, "rate_type")}
                  nodes={this.props.dynamicFilters.rate_type_nodes}
                  checked={this.props.dynamicFilters.rate_type_values}
                />
              </span>
            </td>
            <td colspan="2">
              {/* colspan combines two columns into one within a table */}
              <div id='merged_col'>
                <span id='hide_cruise_text'>
                  <label>
                      Hide unavailable cruises
                      <input
                        class='hide_box'
                        name="hide_unavailable"
                        type="checkbox"
                        checked={this.props.filterData.hide_unavailable}
                        onChange={(e) => this.props.handleSortChange(e, "hide_unavailable")} />
                  </label>
                </span>
                <br/>
                <label>
                    Display price per night
                    <input
                      class='nightly_box'
                      name="display_price_per_night"
                      type="checkbox"
                      checked={this.props.displayPricePerNight}
                      onChange={this.props.handleDisplayPriceChange} />
                </label>
              </div>
            </td>
          </tr>
        </table>
        <div  id='date_durration_container'>
          <table>
            <tr>
              <td id='min_dur_col'>
                <label  id='min_duration'>
                  Min duration:
                  <input
                    id='min_duration_input'
                    name="min_duration"
                    type="number"
                    step="1"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    value={this.props.filterData.min_duration}
                    onChange={this.props.handleSortChange} />
                </label>
              </td>
              <td id='max_dur_col'>
                <label id='max_duration'>
                  Max duration:
                  <input
                    id='max_duration_input'
                    name="max_duration"
                    type="number"
                    step="1"
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    value={this.props.filterData.max_duration}
                    onChange={this.props.handleSortChange} />
                </label>
                <br />
              </td>
              <td id='min_date_col'>
                <label id='min_date'>
                  Min depart date:
                  <input
                    id='min_date_input'
                    name="min_depart_date"
                    type="date"
                    min="2023-02-13"
                    value={this.props.filterData.min_depart_date}
                    onChange={this.props.handleSortChange} />
                </label>
              </td>
              <td id='max_date_col'>
                <label id='max_date'>
                  Max depart date:
                  <input
                    id='max_date_input'
                    name="max_depart_date"
                    type="date"
                    min="2023-02-13"
                    value={this.props.filterData.max_depart_date}
                    onChange={this.props.handleSortChange} />
                </label>
                <br />
              </td>
              <td class="giant_buffer"><span> Updated: {this.props.data_last_updated}</span></td>
              <td><div id='about_link'> <Link id = 'link_text' to='/about'>Info</Link> </div></td>
            </tr>
          </table>
        </div>
      </form>
      </div>
    );
  }
}


function ErrorMessage(props) {
  if (props.text === "") {
    return
  }
  return <div id='error_message'>{props.text}</div>
}

class DataTable extends React.Component {
  constructor(props) {
      super(props);
      this.state = {}

  }

  displayRateInclusions(category) {
    if (!category['price_inclusions'] || (category['price_inclusions'].length === 0)) {
      return
    }
    return (
      <li id='icons'>
        {category['price_inclusions']['drinks'] ? <div class='icon_hover'><FaCocktail/>  <span class='icon_text'>Drinks {category['price_inclusions']['drinks']}</span></div> : ""}
        {category['price_inclusions']['gratuities'] ? <div class='icon_hover'><RiHandCoinLine/>  <span class='icon_text'>Tips {category['price_inclusions']['gratuities']}</span></div> : ""}
        {category['price_inclusions']['wifi'] ? <div class='icon_hover'><FaWifi/>  <span class='icon_text'>Wifi {category['price_inclusions']['wifi']}</span></div> : ""}
      </li>
    )
  }

  renderPrice(this_category, rounded, price_per_night) {
    if (rounded) {
      if (price_per_night) {
        return this_category['price_per_night_rounded']
      }
      return this_category['price_rounded']
    }

    // not rounded
    if (price_per_night) {
      return this_category['price_per_night_exact'] 
    }

    return this_category['price_exact'] 

  }

  renderCategory(category_prices, showAll, outside_i) {
    if (category_prices == null) {
      return <td onClick={() => this.props.onTableRowClick(outside_i)}></td>
    }
    if (!showAll) {
      category_prices = category_prices.slice(0,1)
    }
    let outHTML = [];
    for (let i = 0; i < category_prices.length; i = i + 1) {
      const this_category = category_prices[i]
      outHTML.push(
        <ul key={i}>
          <li>{this_category['name']}</li>
          <li class='total_price'>${this.renderPrice(this_category, !showAll, this.props.displayPricePerNight)}</li>
          {this_category['onboard_credit'] > 0 ? <li id='credit'><div class='icon_hover'><span id='credit_test'><FaRegMoneyBillAlt/></span><span id='credit_amount'> = ${this_category['onboard_credit']}</span> <span class='icon_text'>Onboard credit</span></div></li> : ""}
          {this.displayRateInclusions(this_category)}
        </ul>
      )
    }
    const this_class = showAll ? 'expanded_table_element' : ''
    return <td  onClick={() => this.props.onTableRowClick(outside_i)}>
      <div class={this_class}>
      {outHTML}
      </div>
      </td>
  }

  renderList(itinerary, showAll, outside_i) {
    if (!itinerary) {
      return <td></td>
    }
    if (itinerary.length > 5 && !showAll) {
      itinerary = itinerary.slice(0,4)
      itinerary.push('...')
    }
    const this_class = showAll ? 'expanded_table_element' : ''
    return (
      <td id='itinerary' onClick={() => this.props.onTableRowClick(outside_i)}>
        <div class={this_class}>
        <ol>
          {itinerary.map((item, i) => <li key={i}>{item}</li>)}
        </ol>
        </div>
      </td>
    )
  }

  makeTableBody() {
    if (this.props.data === null & this.props.data_status === 'loaded') {
      return
    }
      // Initialize variable
    let tableHTML = [];

    // For loop to generate string
    for (let i = 0; i < this.props["data"].length; i = i + 1) {
      const thisRow = this.props["data"][i];
      const showAll = this.props.table_rows_expanded.includes(i)
      tableHTML.push(
        <tr key={i} class={showAll ? 'colored_element' : ''}>
          <td id='info_element'><div><p id='brand_name'>{thisRow["company"]}</p>
             {thisRow["cruise_name"]} <br />
             {thisRow["ship"]} <br />
             {/* target and rel make the link open in a new tab */}
             <a href={thisRow['link']} target="_blank" rel="noopener noreferrer">{thisRow["depart_date"]}</a>
             </div>
          </td>
          <td><div>{thisRow["num_nights"]}</div></td>
          {/* onClick for any of the data points below */}
          {this.renderList(thisRow["itinerary"], showAll, i)}
          {this.renderCategory(thisRow["inside"], showAll, i)}
          {this.renderCategory(thisRow["ocean_view"], showAll, i)}
          {this.renderCategory(thisRow["balcony"], showAll, i)}
          {this.renderCategory(thisRow["mini_suite"], showAll, i)}
          {this.renderCategory(thisRow["suite"], showAll, i)}
        </tr>
      );
    }
    return tableHTML;
  }

  renderTable() {
    return (
      <div id='table_container'>
        <table class='maintable'>
          <tbody>
          <tr id='table-headers'>
            <th id='info_head'>Info</th>
            <th id='num_nights'># of Nights</th>
            <th id='itinerary_header'>Itinerary</th>
            <th id='inside'>Inside</th>
            <th id='ocean_view'>Ocean View</th>
            <th id='balcony'>Balcony</th>
            <th id='mini_suite'>Mini Suite</th>
            <th id='suite'>Suite</th>
          </tr>
          {this.makeTableBody()}
          </tbody>
        </table>
      </div>
    );
  }

  

  render() {
    if (this.props.data === null & this.props.data_status === 'not loaded') {
      return <p>Data loading..</p>
    }
    if (this.props.data === null & this.props.data_status === 'erorred') {
      return <p>Error retreiving data</p>
    }
    return (
      <div>
      <p id='data_status'>Data status: {this.props.data_status}</p>
      <div>{this.renderTable()}</div>
      </div>
    )
  }
}

export default App;
